
import { defineComponent, onMounted, ref, reactive } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { useToast } from "vue-toastification";
import ApiService from "@/core/services/ApiService";
import { useRouter, useRoute } from "vue-router";
import store from "@/store";
import { Mutations, Actions } from "@/store/enums/StoreEnums";

import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import Multiselect from "@vueform/multiselect";
import data from "./data";

export default defineComponent({
  title: "Detail Sekolah",
  components: {
    ErrorMessage,
    Field,
    Form,
    Loading,
    Multiselect,
  },

  data() {
    return {
      isLoading: false,
      fullPage: true,
      isColor: "#007BFF",
      isBackgroundColor: "#A3A3A3",
      isOpacity: 0.5,
      noImg: "/media/no-image/No_Image_Horizontal_TKI.png",
      urlImage: {
        url1: "",
        url2: "",
        url3: "",
        url4: "",
        url5: "",
      },
      url: "https://st4.depositphotos.com/14953852/24787/v/600/depositphotos_247872612-stock-illustration-no-image-available-icon-vector.jpg",
      deleteImg: "./assets/images/delete-product.svg",
      uploadImg: "./assets/images/upload-product.svg",
      errorsMessage: {
        errortitle: "",
        errortype: "",
        errorprice: "",
      },

      detail: {
        title: "",
        createTime: "2022-02-15T13:33:33",
        creatorId: "",
        deleteBy: "",
        deleteTime: "",
        description: "",
        discount: 0,
        editorId: "",
        imageshow: "",
        imageProduct: [],
        isDelete: false,
        // price: 0,
        type: "",
        stock: 0,
        updateTime: "",
        schoolName: "",
        startDate: "",
        endDate: "",
        price: 0,
        _id: "",

        idTransactioin: "",
        name: "",
        date: null,
        jumlahPesanan: 0,
        paymentStatus: "",
        orderStatus: "",
        checkData: "",
      },

      showfoto2: false,
      showfoto3: false,
      showfoto4: false,
      showfoto5: false,
      oldDataFileImage: {
        file1: "",
        file2: "",
        file3: "",
        file4: "",
        file5: "",
      },
      targetDataImage: {
        file1: "",
        file2: "",
        file3: "",
        file4: "",
        file5: "",
        file1Ready: "",
        file2Ready: "",
        file3Ready: "",
        file4Ready: "",
        file5Ready: "",
      },
      listCategory: [],
      listType: [],

      jumlahfoto: 1,
      imgCount: 5,
      existingIndex: {},
      uploadIndex: {},
    };
  },

  beforeMount() {
    store.dispatch(Actions.VERIFY_AUTH);
    // this.getListType();
    this.getDetail();
  },

  methods: {
    triggerInputFile(index) {
      console.log(index);
      let element = document.getElementById(`input-file-${index + 1}`);
      element?.click();
      // console.log(element);
    },
    changeImage(item) {
      console.log(item);
      this.detail.imageshow = item;
    },
    formatPrice(value) {
      let val = (value / 1).toFixed(2).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    getListKategori() {
      this.isLoading = true;
      ApiService.getWithoutSlug("crmv2/product/util/combo/category").then(
        ({ data }) => {
          this.listCategory = data;
        }
      );
      this.isLoading = false;
    },
    getListType() {
      this.isLoading = true;
      ApiService.getWithoutSlug("crmv2/product/util/combo/type").then(
        ({ data }) => {
          this.listType = data;
        }
      );
      this.isLoading = false;
    },

    getDetail() {
      store.dispatch(Actions.VERIFY_AUTH);

      this.isLoading = true;
      const $route = useRoute();

      setTimeout(() => {
        let detail: any = data.find(
          (item: any) => item.id == this.$route.params._id
        );
        console.log(detail);
        this.detail.schoolName = detail.schoolName;
        this.detail.price = detail.price;
        this.detail.name = detail.name;
        this.detail.idTransactioin = detail.idTransactioin;
        this.detail.date = detail.date;
        this.detail.jumlahPesanan = detail.jumlahPesanan;
        this.detail.paymentStatus = detail.paymentStatus;
        this.detail.orderStatus = detail.orderStatus;
        this.detail.checkData = detail.checkData;
        this.isLoading = false;
      }, 1000);
      const toast = useToast();
      // ApiService.getWithoutSlug("crmv2/product/admin/" + this.$route.params._id)
      //   .then((res) => {
      //     this.detail = res.data;
      //     this.detail.imageshow = res.data.imageProduct[0];
      //     let existI = [];
      //     for (let i = 0; i < this.imgCount; i++) {
      //       let fotoparam = res.data.imageProduct[`image${i + 1}`];
      //       this.existingIndex[i] = null;
      //       this.uploadIndex[i] = null;
      //       if (fotoparam) this.existingIndex[i] = i;
      //       ApiService.getWithoutSlug(
      //         "crmv2/main_image/image/get/base64/" + fotoparam
      //       )
      //         .then((resImg) => {
      //           this.oldDataFileImage[`file${i + 1}`] = fotoparam;
      //           this.urlImage[`url${i + 1}`] =
      //             "data:image/jpg;base64," + resImg.data;
      //         })
      //         .catch((err) => {
      //           toast.error(err?.res?.data?.detail);
      //           this.isLoading = false;
      //         });
      //     }
      //     console.log(this.existingIndex);

      //     this.isLoading = false;
      //   })
      //   .catch((e) => {
      //     toast.error(e?.res?.data?.detail);

      //     this.isLoading = false;
      //   });
    },

    submitData() {
      const toast = useToast();

      if (
        this.detail.title === "" ||
        this.detail.type === "" ||
        this.detail.price < 0
      ) {
        if (this.detail.title === "") {
          this.errorsMessage.errortitle = "Nama Produk Wajib Diisi";
        }

        if (this.detail.type === "") {
          this.errorsMessage.errortype = "Kategori Produk Wajib Diisi";
        }

        if (this.detail.price < 0) {
          this.errorsMessage.errorprice = "Harga Produk Tidak Boleh Minus";
        }
      } else {
        this.isLoading = true;

        console.log("edit");

        this.errorsMessage.errortitle = "";
        this.errorsMessage.errortype = "";
        this.errorsMessage.errorprice = "";

        const data = {
          title: this.detail.title,
          description: this.detail.description,
          price: this.detail.price,
          stock: this.detail.stock,
          discount: this.detail.discount,
          imageProduct: this.detail.imageProduct,
          type: this.detail.type,
        };

        let rslt = "";
        let idNewProduct = this.detail._id;
        let fileImg = [];
        let image = "";

        let formData = new FormData();
        for (let index = 0; index < this.imgCount; index++) {
          if (this.targetDataImage[`file${index + 1}`]) {
            formData.append("files", this.targetDataImage[`file${index + 1}`]);
          }
        }
        ApiService.postWithData(
          "/crmv2/main_image/image/uploads/" + idNewProduct,
          formData
        )
          .then((res) => {
            this.handleImages(res.data.process.items).map((img, i) => {
              data.imageProduct[`image${i + 1}`] = img;
            });

            ApiService.putWithData("crmv2/product/admin/" + idNewProduct, data)
              .then((res) => {
                toast.success("Berhasil Tambah File");

                this.$router.push("/superadmin/master-produk");
              })
              .catch((e) => {});
          })
          .catch((e) => {
            this.isLoading = false;
            rslt = "error";
            toast.error(e.response.data.detail);
          });
      }
    },

    handleImages(items) {
      let temIndex = 0;
      let imageResult: any[] = [];

      for (let index = 0; index < 5; index++) {
        imageResult[index] = null;
        if (this.existingIndex[index] != null) {
          imageResult[index] = this.detail.imageProduct[`image${index + 1}`];
        }
        if (this.uploadIndex[index] != null) {
          imageResult[index] = items[temIndex];
          temIndex++;
        }
      }
      return imageResult;
    },

    addfoto() {
      if (this.jumlahfoto < 5) {
        switch (this.jumlahfoto) {
          case 1:
            this.showfoto2 = true;
            break;
          case 2:
            this.showfoto3 = true;
            break;
          case 3:
            this.showfoto4 = true;
            break;
          case 4:
            this.showfoto5 = true;
        }
        let jumlahfotosekarang = this.jumlahfoto + 1;
        this.jumlahfoto = jumlahfotosekarang;
        console.log(jumlahfotosekarang);
      } else {
        const toast = useToast();
        toast.error("Foto Maksimal 5");
      }
    },

    handleFileUpload(event, key) {
      this.uploadIndex[key - 1] = key;
      this.urlImage[`url${key}`] = URL.createObjectURL(event.target.files[0]);
      this.targetDataImage[`file${key}`] = event.target.files[0];
      this.targetDataImage[`file${key}Ready`] = true;
    },
  },
});
