import moment from "moment";

const data = [
  {
    _id: "1",
    idTransactioin: "1",
    schoolName: "SMA TKI 1",
    name: "",
    type: "PRODUCT",
    date: moment(new Date()).format("DD MMMM YYYY"),
    jumlahPesanan: 1000000,
    paymentStatus: "",
    orderStatus: "CANCELED",
    checkData: "PREVIEW",
  },
  {
    _id: "2",
    idTransactioin: "1",
    schoolName: "SMA TKI 1",
    name: "",
    type: "PRODUCT",
    date: moment(new Date()).format("DD MMMM YYYY"),
    jumlahPesanan: 1000000,
    paymentStatus: "",
    orderStatus: "CANCELED",
    checkData: "PREVIEW",
  },
];

export default data;
